<template>
  <b-container class="page-container">
    <Breadcrumbs :currentpage="$t('confirmation-emailadres')" />
    <b-row>
      <b-col xs="12" md="12">
        <h1 class="pb-3 text-center">{{ $t('confirmation-emailadres') }}</h1>
        <p>{{ $t('were-looking-at-your-request') }}</p>
        <ul>
          <li>{{ $t('after-registration-receive-confirmation') }}.</li>
          <li>
            {{ $t('not-receiving-registration-mail') }}.
            {{ $t('optionally-use-the') }}
            <router-link
              :to="{
                name: 'forgotPassword',
                params: { lang: this.$i18n.locale }
              }"
              >{{ $t('forgot-password-lowercase') }}</router-link
            >
            {{ $t('form-to-get-access') }}.
          </li>
        </ul>

        <p>{{ $t('login-possible-after-email-confirmation') }}.</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Breadcrumbs from '@/components/header/BreadCrumbs'
export default {
  components: { Breadcrumbs }
}
</script>

<style scoped lang="scss">
ul {
  list-style-type: inherit;
  margin-left: 20px;
}
.page-container {
  h4 {
    font-size: 35px;
  }
  p {
    font-size: 18px;
  }
}
</style>
